import React from "react";
import Head from "next/head";
import { Header } from "components";
import LoginPage from "features/account/pages/LoginPage";

const Login = () => {
  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <Header />
      <LoginPage />
    </>
  );
};

export default Login;
