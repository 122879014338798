import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    marginTop: "30px",
    justifyContent: "left"
  },
  title: {
    font: "normal normal 600 16px/30px Poppins",
    color: "#2E323C",
    marginLeft: "30px"
  },
  text: {
    color: "#2E323C",
    font: "normal normal normal 12px/21px Poppins",
    marginLeft: "30px",
    width: "300px"
  },
  image: {
    height: "80px",
    width: "80px"
  },
  bubblesDecorate: {
    marginTop: "31px"
  },
  titleDescription: {
    font: "normal normal 600 16px/30px Poppins",
    color: "#2E323C"
  },
  square: {
    marginLeft: "650px",
    position: "absolute"
  },
  "@media (max-width: 1130px)": {
    square: {
      marginLeft: "500px"
    }
  },
  "@media (max-width: 965px)": {
    square: {
      marginLeft: "400px"
    }
  },
  "@media (max-width: 860px)": {
    square: {
      display: "none"
    }
  },
  "@media (max-width: 815px)": {
    text: {
      width: "250px",
      marginLeft: "10px"
    },
    title: {
      marginLeft: "10px"
    },
    bubblesDecorate: {
      display: "none"
    }
  }
}));

const LoginBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Typography className={classes.titleDescription}>
          Como funciona:
        </Typography>
        <img className={classes.square} src="/img/squareDecorate.svg" alt="" />
      </div>
      <div className={classes.card}>
        <img className={classes.image} src="/img/credits.png" alt="" />
        <div>
          <Typography className={classes.title}>2 créditos</Typography>
          <Typography className={classes.text}>
            Cada crédito vale uma oferta em um veículo.
          </Typography>
        </div>
      </div>
      <div className={classes.card}>
        <img className={classes.image} src="/img/reserved.svg" alt="" />
        <div>
          <Typography className={classes.title}>Reservou!</Typography>
          <Typography className={classes.text}>
            Quando sua oferta entrar no status "Reservado" você tem 24 horas
            para visitar e pagar.
          </Typography>
        </div>
      </div>
      <div className={classes.card}>
        <img className={classes.image} src="/img/bought.svg" alt="" />
        <div>
          <Typography className={classes.title}>Comprou!</Typography>
          <Typography className={classes.text}>
            Ao efetivar a compra de um veículo é consumido 1 crédito que é
            renovada após 6 meses.
          </Typography>
        </div>
      </div>
      <img
        className={classes.bubblesDecorate}
        src="/img/bubblesDecorate.svg"
        alt=""
      />
    </div>
  );
};

export default LoginBanner;
