import React, { useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { signIn } from "next-auth/react";
import { useForm } from "react-hook-form";
import { makeStyles, Typography } from "@material-ui/core";
import { Button, InputPassword, InputText } from "@loop-brasil/material";
import ValidationError from "features/account/components/ValidationError";

const useStyles = makeStyles({
  form: {
    backgroundColor: "#fff",
    border: "1px solid #FFCFD8",
    boxShadow: "0px 1px 2px #00000029",
    borderRadius: "8px",
    marginBottom: "40px"
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "480px",
    padding: "32px 24px"
  },
  login: {
    position: "relative",
    height: "82px",
    "&::after": {
      position: "absolute",
      backgroundColor: "#E9ECEE",
      top: "66%",
      left: "47%",
      width: "25px",
      height: "5px",
      borderRadius: "3px"
    }
  },
  subtitle: {
    marginTop: "15px",
    font: "normal normal 300 14px/21px Poppins",
    color: "#2E323C",
    textAlign: "center"
  },
  link: {
    textAlign: "right",
    fontSize: "0.8rem",
    "& a": {
      color: "#2E323C"
    },
    "& a:hover": {
      color: "#1E212C"
    }
  }
});

const LoginForm = () => {
  const classes = useStyles();
  const router = useRouter();
  const { next } = router.query;
  const [error, setError] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues
  } = useForm();

  const onSubmit = async data => {
    const username = data.email.trim();
    const password = data.password.trim();

    const res = await signIn("credentials-auth", {
      username,
      password,
      redirect: false
    });

    if (!res) return;

    if (res.ok) {
      router.push(next ?? "/");
    }

    if (res.error) {
      setError(JSON.parse(res.error));
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.box}>
        <div className={classes.login}>
          <Typography align="center" variant="h3">
            Login
          </Typography>
          <Typography className={classes.subtitle}>
            Área Loop para colaboradores Santander.
          </Typography>
        </div>
        <ValidationError
          message={error.message}
          type={error.type}
          changeError={setError}
          email={getValues("email")}
        />
        <InputText
          id="email"
          label="email"
          className={classes.input}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          {...register("email", { required: "Insira um e-mail válido" })}
        />
        <InputPassword
          id="password"
          label="Senha"
          className={classes.input}
          autoComplete="current-password"
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          {...register("password", {
            required: "O campo senha não pode estar vazio"
          })}
        />
        <div className={classes.link}>
          <Link href="/esqueci-minha-senha">
            <a>Esqueceu sua senha?</a>
          </Link>
        </div>
        <Button fullWidth className={classes.button} type="submit">
          Entrar
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
