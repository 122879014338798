import React from "react";
import { makeStyles, Container, Typography } from "@material-ui/core";
import LoginBanner from "../components/LoginBanner";
import LoginForm from "../components/LoginForm";
import { Footer } from "components";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "30px"
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap-reverse"
  },
  "@media (max-width: 815px)": {
    content: {
      justifyContent: "center",
      marginTop: "30px"
    },
    root: {
      textAlign: "center"
    }
  }
}));

const LoginPage = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Container>
        <div>
          <img src="/img/santander.png" alt="" />
          <Typography variant="h1">Nossas boas vindas!</Typography>
        </div>
        <div className={classes.content}>
          <LoginBanner />
          <LoginForm />
        </div>
      </Container>
      <Footer />
    </main>
  );
};

export default LoginPage;
