import { instance } from "api";
import { useMutation } from "react-query";

export const postTelephoneResend = email =>
  instance.post("/registration/confirmation/resend-sms", { email });

export const useResendSms = () => {
  const resendSms = useMutation(["RESEND_SMS"], email =>
    postTelephoneResend(email)
  );

  return resendSms;
};
