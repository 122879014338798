import React from "react";
import { Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useResendEmail } from "../api/resendEmail";
import { useResendSms } from "../api/resendSms";

const ValidationError = ({ message, type, email, changeError }) => {
  const resendEmail = useResendEmail();
  const resendSms = useResendSms();

  const handleResendEmail = () => {
    resendEmail.mutate(email);

    changeError({
      type: "feedback",
      message: "Enviamos o link de confirmação para seu e-mail"
    });
    setTimeout(() => changeError({}), 3000);
  };

  const handleResendTelephone = () => {
    resendSms.mutate(email);

    changeError({
      type: "feedback",
      message: "Enviamos um sms de confirmação para seu telefone"
    });
    setTimeout(() => changeError({}), 3000);
  };

  if (!message) {
    return <></>;
  }

  if (type === "unverified_sms")
    return (
      <Alert severity="error">
        {message}{" "}
        <Link style={{ cursor: "pointer" }} onClick={handleResendTelephone}>
          Reenviar sms de confirmação
        </Link>
      </Alert>
    );

  if (type === "unverified_email")
    return (
      <Alert severity="error">
        {message}{" "}
        <Link style={{ cursor: "pointer" }} onClick={handleResendEmail}>
          Reenviar e-mail de confirmação
        </Link>
      </Alert>
    );

  if (type === "feedback") return <Alert severity="info">{message}</Alert>;

  return (
    <Alert severity="error">O usuário informou credenciais inválidas.</Alert>
  );
};

export default ValidationError;
