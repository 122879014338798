import { useMutation } from "react-query";
import { instance } from "api";

export const postEmailResend = email =>
  instance.post("/registration/confirmation/resend-email", { email });

export const useResendEmail = () => {
  const resendEmail = useMutation(["RESEND_EMAIL"], email =>
    postEmailResend(email)
  );

  return resendEmail;
};
